import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Alert, Checkbox, Stack, Typography, useTheme } from '@mui/material';
import { PageContainer } from './components/PageContainer';
import PflegenaviLogo from './assets/pflegenavi_logo.svg';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingPromiseButtonAdvanced } from '@pflegenavi/web-components';
import {
  shouldUseNewLogin,
  useAuthentication,
} from '@pflegenavi/shared-frontend/authentication';

export const TermsAndConditions: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const auth = useAuthentication(shouldUseNewLogin());

  const [accepted, setAccepted] = useState(false);

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAccepted(event.target.checked);
    },
    [setAccepted]
  );

  const handleClickLabel = useCallback(() => {
    setAccepted(!accepted);
  }, [accepted]);

  const handleClickAnchor = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.stopPropagation();
    },
    []
  );

  return (
    <PageContainer>
      <Stack gap={3} width={550} alignItems="center">
        <img src={PflegenaviLogo} width={360} />
        <Stack gap={2} width="100%" alignItems="center">
          <Typography variant="h4" mb={-1}>
            {t('authentication.accept-terms-title')}
          </Typography>
          <Alert severity="info">
            {t('authentication.data-privacy-importance')}
          </Alert>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            width="100%"
            sx={{
              cursor: 'pointer',
            }}
            onClick={handleClickLabel}
          >
            <Checkbox
              color="info"
              checked={accepted}
              onChange={handleCheckboxChange}
            />
            <Trans
              i18nKey="authentication.i-accept-terms-conditions"
              components={{
                bold: <strong />,
                link1: (
                  <a
                    href="https://pflegenavi.at/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClickAnchor}
                    style={{
                      color: theme.palette.info.main,
                      textDecoration: 'none',
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  />
                ),
                link2: (
                  <a
                    href="https://pflegenavi.at/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClickAnchor}
                    style={{
                      color: theme.palette.info.main,
                      textDecoration: 'none',
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  />
                ),
              }}
            />
          </Stack>
          <LoadingPromiseButtonAdvanced
            onClick={auth?.acceptTermsAndConditions}
            disabled={!accepted}
            variant="contained"
            type="submit"
            sx={{
              fontSize: 16,
              width: 170,
            }}
          >
            {t('authentication.confirm')}
          </LoadingPromiseButtonAdvanced>
        </Stack>
      </Stack>
    </PageContainer>
  );
};
